import RequestService from './request.service';
import { getItems, getItem, postItem, putItem, patchItem, deleteItems, deleteItem, postFile } from './common.service';

class UserService {
  route = 'admin/users';
  imageRoute = 'admin/images';
  getMe(checkIdle=true) {
    return RequestService.get('users/me',{},'json',true,checkIdle)
      .then(response => {
        return Promise.resolve({
          id: response.data[0].id,
          givenName: response.data[0].givenName,
          shortFamilyName: response.data[0].shortFamilyName,
          operates: response.data[0].operates
        });
      })
      .catch( error => Promise.reject(error) );
  };
  getItems(sortBy, sortDesc, page, perPage, filter) {
    return getItems(this.route, sortBy, sortDesc, page, perPage, filter).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  getItemByEmail(email) {
    return RequestService.get('admin/users/email?email='+email,{},'hydra')
      .then( response => {
        if (response.data['hydra:totalItems'] == 1) {
          return Promise.resolve(response.data['hydra:member'][0]);
        }
        return Promise.reject();
      });
  };
  getItem(id) {
    return getItem(this.route,id)
      .then(response => {
        // key is needed for geocomplete component
        if (response.homeAddress) {
          response.homeAddress.key = 0;
        }
        // we add a newImage field to track image change
        return Promise.resolve({
          ...response,
          newImage: false
        });
      })
      .catch( error => Promise.reject(error) );
  };
  postItem(item) {
    return postItem(this.route, item)
      .then(response => {
        // key is needed for geocomplete component
        if (response.homeAddress !== undefined) {
          response.homeAddress.key = 0;
        }
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );
  };
  putItem(id,item) {
    return putItem(this.route, id, item)
      .then(response => {
        // key is needed for geocomplete component
        if (response.homeAddress !== undefined) {
          response.homeAddress.key = 0;
        }
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );
  };
  patchItem(id,item) {
    return patchItem(this.route, id, item)
      .then(response => {
        // key is needed for geocomplete component
        if (response.homeAddress !== undefined) {
          response.homeAddress.key = 0;
        }
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );
  };
  deleteItems(params) {
    return getItems(`${this.route}/delete?${params}`, params).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  deleteItem(id) {
    return deleteItem(this.route, id).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  postImage(item) {
    return postFile(this.imageRoute, item).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  associate(campaign,filterType,filter,deliveries=[]) {
    let params = {
      campaignId: campaign,
      filterType: filterType
    };
    for (const [key, value] of Object.entries(filter)) {
      if (value != '' && value !== null) {
        params[key] = value;
      }
    }
    if (deliveries.length>0) {
      params['user'] = deliveries;
    }
    return RequestService
      .get(this.route+'/associate-campaign', params, 'hydra')
      .then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  sendCampaign(campaign,mode,filters) {
    let params = {
      campaignId: campaign,
      mode: mode
    };
    // filters is an array of { key, values } where values is also an array
    // we have to convert the values to a single element if there's only one value 
    // and let them as array if there are multiple values
    // this way it will be correctly added to the query string
    filters.forEach(element => {
      if (element.values.length>1) {
        params[element.key] = element.values;
      } else if (element.values[0] != '' && element.values[0] !== null) {
        params[element.key] = element.values[0];
      }      
    });
    return RequestService
      .get(this.route+'/send-campaign', params, 'hydra')
      .then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  exportAll(params = {}) {
    return RequestService.get(`${this.route}/export`, params, 'csv')
      .then((r) => Promise.resolve(r), error => Promise.reject(error));
  };
  exportFiltered(filter = {}){
    return RequestService.get(`${this.route}/export`, filter, 'csv')
      .then((r) => Promise.resolve(r), error => Promise.reject(error));
  };
  exportSelected(selection=[]){
    let filter = {
      selected: selection.join(',')
    }
    return RequestService.get(`${this.route}/export`, filter, 'csv')
      .then((r) => Promise.resolve(r), error => Promise.reject(error));
  };
  exportExtended(){
    return RequestService.get(`${this.route}/export`, {exportType: 1}, 'csv')
      .then((r) => Promise.resolve(r), error => Promise.reject(error));
  }
}

export default new UserService();